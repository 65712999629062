import React, { useContext, useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageBackgroundColor } from "../components/context/page-background-color";

// Components
import { PageSEO } from "../components/seo/page-seo";
import { Lightbox } from "../components/images/lightbox-gallery";

// Icons
import { Next, Prev } from "../components/icons/arrows";

const Page = styled.div`
  margin: 25px 0 170px 0;

  @media (max-width: 768px) {
    margin: 25px 0 95px 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;

  & .left-column {
    grid-column: 1 / 9;

    & .artwork-image-container {
      cursor: pointer;

      margin: 0 0 90px 0;

      & .image-caption {
        margin: 10px 0 0 0;

        max-width: 660px;

        & p {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.05em;

          margin: 0;
        }
      }
    }

    & .title-container {
      margin: 0 0 70px 0;

      & p {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.05em;

        margin: 0;

        &.dates {
          line-height: 26px;
        }
      }

      @media (max-width: 768px) {
        text-align: center;
      }
    }

    & .text-container {
      max-width: 815px;

      & p {
        margin: 0;
        text-indent: 99px;

        @media (max-width: 768px) {
          text-indent: 35px;
        }

        &:first-of-type {
          text-indent: unset;
        }
      }

      & .credit-text {
        margin: 27px 0 0 0;

        max-width: 615px;

        & p {
          font-size: 11px;
          line-height: 17px;
          letter-spacing: 0.05em;
        }
      }

      & .read-more-link {
        margin: 25px 0 0 0;
        transition: 250ms color ease;

        &:hover {
          color: #163262;

          & svg {
            transition: 250ms stroke ease, 250ms fill ease;

            & path {
              &:first-of-type {
                stroke: #163262;
              }

              &:last-of-type {
                fill: #163262;
              }
            }
          }
        }

        & p {
          margin: 0;

          &:first-of-type {
            display: inline-block;
          }

          & svg {
            width: 27px;
            height: auto;

            margin: 0 0 0 15px;
          }
        }
      }
    }

    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }

  @media (max-width: 768px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;

    & .left-column {
      grid-column: unset;
      padding: 0;

      & .artwork-image-container {
        margin: 0 0 40px 0;
      }

      & .details {
        margin: 1em 0 0 0;

        & p {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.04em;
        }
      }
    }
  }

  & .mobile-title {
    display: none;
    text-align: center;
    margin: 0 0 35px 0;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;

  margin: 60px 0 0 0;

  & svg {
    &:first-of-type {
      margin: 0 40px 0 0;
    }
  }

  & a {
    &:hover {
      color: #163262;

      & svg {
        & path {
          transition: 250ms stroke ease, 250ms fill ease;

          &:first-of-type {
            stroke: #163262;
          }

          &:last-of-type {
            fill: #163262;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Exhibition = ({ data, location }) => {
  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );

  // Tags and filters
  const [activeTag, setActiveTag] = useState(`all`);

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [activeSlideNumber, setActiveSlideNumber] = useState(0);

  useEffect(() => {
    setPageBackgroundColor({
      text: `#4383EF`,
      background: `#ffffff`,
    });
  }, []);

  useEffect(() => {
    if (
      location !== null &&
      location !== undefined &&
      location !== `undefined`
    ) {
      if (
        location.state !== null &&
        location.state !== undefined &&
        location.state !== `undefined`
      ) {
        if (location.state.activeTag !== undefined) {
          setActiveTag(location.state.activeTag);
        }
      }
    }
  }, [location]);

  const images = data.prismicExhibition.data.images
    .filter(content => content.image.fluid !== null)
    .map((content, index) => (
      <div
        className="artwork-image-container"
        key={`single_artwork_image_${index}`}
        onClick={() => {
          setIsLightboxOpen(true);
          setActiveSlideNumber(index);
        }}
      >
        <img
          srcSet={content.image.fluid.srcSetWebp}
          src={content.image.fluid.srcWebp}
          alt={content.image.alt}
          loading={
            index <= 2 ||
            index === data.prismicExhibition.data.images.length - 1
              ? `eager`
              : `lazy`
          }
        />
      </div>
    ));

  const seoImage =
    data.prismicExhibition.data.images.length >= 1 &&
    data.prismicExhibition.data.images[0].image.fluid !== null
      ? data.prismicExhibition.data.images[0].image.url
      : null;

  // All Article Links
  const allExhibitions = data.allPrismicExhibition.edges.map(
    article => article.node.uid
  );

  // Current article
  const currentArticleNumber = allExhibitions.indexOf(
    data.prismicExhibition.uid
  );

  // Next article link
  const nextArticleUid =
    currentArticleNumber + 1 < allExhibitions.length
      ? allExhibitions[currentArticleNumber + 1]
      : allExhibitions[0];

  // Prev article link
  // const prevArticleUid =
  //   currentArticleNumber === 0
  //     ? allExhibitions[allExhibitions.length - 1]
  //     : allExhibitions[currentArticleNumber - 1];

  return (
    <>
      <PageSEO
        title={data.prismicExhibition.data.title.text}
        description={data.prismicExhibition.data.details.text}
        image={seoImage}
        url={`https://yinkashonibare.com${data.prismicExhibition.url}`}
      />
      <Page>
        <Grid>
          <div className="left-column">
            <div className="title-container">
              <div
                className="title"
                dangerouslySetInnerHTML={{
                  __html: data.prismicExhibition.data.title.html,
                }}
              />
              <div className="details">
                <p>{data.prismicExhibition.data.subtitle.text}</p>
                {data.prismicExhibition.data.details.text.length >= 1 && (
                  <p className="dates">
                    {data.prismicExhibition.data.details.text}
                  </p>
                )}
              </div>
            </div>
            <div className="images-container">{images}</div>
            <div className="text-container">
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: data.prismicExhibition.data.text.html,
                }}
              />

              {data.prismicExhibition.data.link.url !== "" &&
                data.prismicExhibition.data.link.url !== null && (
                  <div className="read-more-link">
                    <a
                      href={data.prismicExhibition.data.link.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              data.prismicExhibition.data.read_more_link.html,
                          }}
                        />

                        <Next />
                      </p>
                    </a>
                  </div>
                )}

              <div
                className="credit-text"
                dangerouslySetInnerHTML={{
                  __html: data.prismicExhibition.data.credits.html,
                }}
              />

              <Navigation>
                <Link
                  to={`/exhibitions/`}
                  state={{
                    activeTag: activeTag,
                    exhibitionId: data.prismicExhibition.id,
                  }}
                >
                  <Prev />
                </Link>

                <Link to={`/exhibitions/${nextArticleUid}`}>
                  <Next />
                </Link>
              </Navigation>
            </div>
          </div>
        </Grid>
      </Page>

      {isLightboxOpen && (
        <Lightbox
          isLightboxOpen={isLightboxOpen}
          setIsLightboxOpen={setIsLightboxOpen}
          images={data.prismicExhibition.data.images}
          activeSlideNumber={activeSlideNumber}
        />
      )}
    </>
  );
};

export default withPreview(Exhibition);

export const query = graphql`
  query Exhibition($uid: String!) {
    allPrismicExhibition(
      sort: { fields: data___publishing_date, order: DESC }
    ) {
      edges {
        node {
          url
          uid
        }
      }
    }
    prismicExhibition(uid: { eq: $uid }) {
      url
      id
      uid
      data {
        title {
          html
          text
        }
        subtitle {
          html
          text
        }
        details {
          html
          text
        }
        exhibition_type
        images {
          image {
            alt
            url
            fluid {
              srcWebp
              srcSetWebp
            }
            dimensions {
              height
              width
            }
          }
        }
        text {
          html
        }
        credits {
          html
        }
        read_more_link {
          html
        }
        link {
          url
        }
      }
    }
  }
`;
